import type { Head } from '../types/head'

import React from 'react'

import Footer from '@components/footer'
import Navbar from '@components/navbar'
import FacebookLivesream from '@components/facebook-livestream'
import { Box } from '@chakra-ui/react'
import Announcement from '@components/announcement'
import NotiFreeShipping from '@components/noti-free-shipping'
import MainContainer from '@components/main-container'
import { NAV_ITEMS_MAIN_LAYOUT } from '@constants/navbar.constant'
import { useResponsive } from '@common/hooks'
import { useRouter } from 'next/router'
import RouterUrl from '@constants/router.constant'
import Image from 'next/image'
import PageHead from '@modules/common/components/head'
import RecaptchaProvider from '@components/footer/recaptcha-provider'
import { ErrorBoundary } from 'react-error-boundary'
import FooterCopyright from '@components/footer/footer-copyright'

interface MainLayoutPropsType {
  children: React.ReactNode
  head?: Omit<Head, 'route'>
  notRenderHead?: boolean
}

const MainLayout = ({
  children,
  head,
  notRenderHead = false
}: MainLayoutPropsType) => {
  const { xlMax } = useResponsive()

  const router = useRouter()

  const is500ErrorPage = router.pathname === RouterUrl['500']

  return (
    <>
      {!notRenderHead && <PageHead {...head} />}
      <Box>
        <Navbar
          navItems={NAV_ITEMS_MAIN_LAYOUT}
          productSection={null}
          logo={
            <Image
              src={'/images/habbit-logo-transition.svg'}
              width={xlMax ? 115 : 165}
              height={xlMax ? 65 : 45}
              alt="habbit-logo-icon"
            />
          }
          onRedirect={() => router.push(RouterUrl.HOME.ROOT)}
        />
        <MainContainer
          minH={is500ErrorPage ? '50vh' : '100vh'}
          display={'flex'}
          flexDirection={'column'}
          mt={xlMax ? '78px' : '96px'}
        >
          <ErrorBoundary fallback={<></>}>
            <NotiFreeShipping />
            <Announcement />
            <FacebookLivesream />
          </ErrorBoundary>
          {children}
        </MainContainer>
        <ErrorBoundary fallback={<FooterCopyright />}>
          <RecaptchaProvider>
            <Footer />
          </RecaptchaProvider>
        </ErrorBoundary>
      </Box>
    </>
  )
}

export default MainLayout
